export const generateEmailString = () => {
    let s = '@'
        , n = 'hello'
        , k = 'activ-business.co.uk'
        , e = n + s + k;

    return e;
};

export const generateTelNo = () => {
    let a = '014',
        l = '94 4',
        m = '112',
        e = '94';
    
    return a + l + m + e;
};