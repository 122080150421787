import styled from 'styled-components';
import { rem } from 'lib/units';
import { minWidth } from 'lib/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Main = styled.main`
    margin-top: ${rem(78)};
    @media(min-width: ${minWidth('lg')}) {
        margin-top: ${rem(119)};
    }
`;

export const Content = styled.div`
    flex: 1 0 auto;
`;
