import React from 'react';
import Icon from './components/icon';
import TwitterSVG from './svg/twitter.svg';

const TwitterIcon = () => (
    <Icon>
        <TwitterSVG />
    </Icon>
);

export default TwitterIcon;