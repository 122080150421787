export const unitless = (px) => {
    const num = px / 16;
    const precision = getPrecision(num);
    if(precision > 3) {
        return num.toFixed(3);
    }
    return num;
};

export const rem = (px) => {
    // TODO use unitless
    return `${unitless(px)}rem`;
}

export const em = (px) => {
    return `${unitless(px)}em`;
}

export const lineHeight = (fontSizePx, lineHeightPx) => {
    return (lineHeightPx / fontSizePx).toFixed(2);
};

function getPrecision(num) {
    if(isNaN(+num)) return 0;
    const decimals = (num + '').split('.')[1];
    if(decimals) return decimals.length;
    return 0;
}
