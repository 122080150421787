import React from 'react';
import { Wrapper } from './styled';

const Address = ({ name, description, line1, line2, town, postcode, tel }) => (
    <Wrapper itemScope itemType="http://schema.org/LocalBusiness">
        <span itemProp="name">{name}</span>
        <meta itemProp="description" content={description} />
        <span itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
            <span>{line1}</span>
            <span itemProp="streetAddress">{line2}</span>
            <span itemProp="addressLocality">{town}</span>
            <span itemProp="postalCode">{postcode}</span>
        </span>
        <meta itemProp="telephone" content={tel} />
    </Wrapper>
);

export default Address;
