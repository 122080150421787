import React from 'react';
import Icon from './components/icon';
import LinkedInSVG from './svg/linkedin.svg';

const FacebookIcon = () => (
    <Icon>
        <LinkedInSVG />
    </Icon>
);

export default FacebookIcon;