import React, { useState } from 'react';
import { Header, Container, LogoLink, NavContainer, Spacer, MenuButtonContainer } from './styled';
// Will work. Problems on refresh are todo with SSR
import LogoImg from 'svg/logo.svg';
import Navigation from 'components/common/layout/navigation';
import MenuButton from 'components/common/layout/menu-button';
import { LinkActionExternal, Link } from 'components/common/link';

import { generateTelNo, generateEmailString } from 'utils/helpers';
import PhoneIcon from 'components/common/icons/phone';
import EnvelopeIcon from 'components/common/icons/envelope';

const email = generateEmailString();
const tel = generateTelNo();

const Index = () => {
    const [ menuOpen, setMenuOpen ] = useState(false);

    return (
        <Container>
            <Header menuOpen={menuOpen}>
                <LogoLink to="/" aria-label="Activ Business Solutions">
                    <LogoImg />
                </LogoLink>
                <NavContainer menuOpen={menuOpen}>
                    <Navigation />
                </NavContainer>
                <Spacer>
                    <LinkActionExternal as="a" href="http://shop.activ-business.co.uk/" size="large">Product Shop</LinkActionExternal>
                </Spacer>
                <MenuButtonContainer>
                    <Link as="a" href={`tel:${tel}`}><PhoneIcon /></Link>
                    <Link as="a" href={`mailto:${email}`}><EnvelopeIcon /></Link>
                    <MenuButton onClick={() => setMenuOpen(!menuOpen)} toggleMenu={menuOpen} />
                </MenuButtonContainer>
            </Header>
        </Container>
    );
};

export default Index;