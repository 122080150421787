import styled from 'styled-components';
import { rem } from 'lib/units';
import { minWidth, maxWidth } from 'lib/breakpoints';

export const List = styled.ul`
    display: flex;
    @media (max-width: ${maxWidth('lg')}) {
        flex-direction: column;
    }
`;

export const Item = styled.li`
    @media (max-width: ${maxWidth('lg')}) {
        margin-bottom: ${rem(12)};
    }
    @media (min-width: ${minWidth('lg')}) {
        margin: 0 ${rem(18)};
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    } 
`;