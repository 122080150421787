import { em } from 'lib/units';

// Extra small <576px
// Small ≥576px
// Medium ≥768px
// Large ≥992px
// Extra large ≥1200px
export const breakpoints = { sm: 576, md: 768, lg: 992, xl: 1200 };
breakpoints.smMax = breakpoints.sm - .02;
breakpoints.mdMax = breakpoints.md - .02;
breakpoints.lgMax = breakpoints.lg - .02;
breakpoints.xlMax = breakpoints.xl - .02;

export const minWidth = (size) => {
    const px = breakpoints[size];

    return `${em(px)}`;
};

export const maxWidth = (size) => {
    const px = breakpoints[`${size}Max`];

    return `${em(px)}`;
};

// @media (min-width: ${minWidth('md')}) {
// @media (max-width: ${maxWidth('sm')}) {