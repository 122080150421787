import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { 
    NavList, 
    NavListItem, 
    SubNavListExtra, 
    SubNavListItem, 
    SubNavList
} from './styled';
import { NavLink, NavLinkPrimary } from 'components/common/link';
import BubblesIcon from 'components/common/icons/bubbles';
import * as colours from 'lib/theme/colours';

export default () => {
    const data = useStaticQuery(graphql`
        query NavQuery {
            services: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: {regex: "/(services)/"},
                    frontmatter: {enabled: {eq: true}, path:{ne: "/services/corporate-social-responsibility"}}
                }, 
                sort: {fields: frontmatter___sort}
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            title
                        }
                    }
                }
            }
            categories: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: {regex: "/(categories)/"},
                    frontmatter: {enabled: {eq: true}}
                }, 
                sort: {fields: frontmatter___title}
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            title
                        }
                    }
                }
            }
        }
    `);

    const { categories, services } = data;

    return (
        <NavList>
            <NavListItem>
                <NavLinkPrimary to={categories.edges[0].node.frontmatter.path}>Categories</NavLinkPrimary>
                <SubNavListExtra>
                    <SubNavList>
                    {categories.edges.map(({ node }, i) => {
                        return (
                            <SubNavListItem key={i}>
                                <NavLink to={node.frontmatter.path}>{node.frontmatter.title}</NavLink>
                            </SubNavListItem>
                        )}
                    )}
                    </SubNavList>
                </SubNavListExtra>
            </NavListItem>
            <NavListItem>
                <NavLinkPrimary to={services.edges[0].node.frontmatter.path}>Services</NavLinkPrimary>
                <SubNavListExtra>
                    <SubNavList>
                        {services.edges.map(({ node }, i) => {
                            return (
                                <SubNavListItem key={i}>
                                    <NavLink to={node.frontmatter.path}>{node.frontmatter.title}</NavLink>
                                </SubNavListItem>
                            )}
                        )}
                    </SubNavList>
                </SubNavListExtra>
            </NavListItem>
            <NavListItem>
                <NavLinkPrimary to="/about" appearance="primary">About</NavLinkPrimary>
            </NavListItem>
            <NavListItem visibility="sidebar">
                <NavLinkPrimary as="a" href="http://shop.activ-business.co.uk/">Stationery Shop</NavLinkPrimary>
            </NavListItem>
            <NavListItem>
                <NavLinkPrimary to="/services/corporate-social-responsibility">CSR</NavLinkPrimary>
            </NavListItem>
            <NavListItem visibility="desktop">
                <NavLinkPrimary to="/contact" colour={colours.HIGHLIGHT}><BubblesIcon height="16px" width="18px" /> Get in touch</NavLinkPrimary>
            </NavListItem>
        </NavList>
    );
};
