import styled from 'styled-components';
import { rem } from 'lib/units';
import { maxWidth } from 'lib/breakpoints';

const IconList = styled.ul`
    display: flex;
    margin-top: ${rem(42)};
    @media (max-width: ${maxWidth('lg')}) {
        justify-content: center;
    } 
`;

IconList.Item = styled.li`
    margin-right: ${rem(12)};
    width: 24px;
    :last-child {
        margin-right: 0;
    }
`;

export default IconList;