import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { rem } from 'lib/units';
import * as colours from 'lib/theme/colours';
import { minWidth, maxWidth } from 'lib/breakpoints';
export { default as LinkAction, LinkActionExternal } from './link-action';

function getAppearance({ appearance }) {
    if(appearance === 'primary') {
        return `
            color: #222222;
            display: block;
            font-family: roboto-bold;
            &:hover {
                color: #707070;
            }
            @media(max-width: ${maxWidth('lg')}) {
                margin-bottom: ${rem(12)};
                font-size: ${rem(20)};
            }
            @media(min-width: ${minWidth('lg')}) {
                padding: 0 ${rem(12)};
                font-size: ${rem(18)};
            }
        `;
    } else if(appearance === 'meta') {
        return `
            color: #ffffff;
            font-family: roboto-bold;
            font-size: ${rem(14)};
            &:hover {
                color: ${colours.BRAND_PRIMARY};
            }
        `;
    } else {
        return `
            color: #222222;
            font-family: roboto-bold;
            font-size: ${rem(14)};
        `;
    }
}

// TODO is the inherit require?
export const NavLink = styled(GatsbyLink)`
    transition: color .3s;
    font-family: 'roboto-regular'; // TODO move to appearance
    font-weight: 500;
    line-height: inherit;
    ${({ uppercase }) => uppercase && `
        text-transform: uppercase;
    `};
    ${getAppearance}
`;

export const NavLinkPrimary = styled(GatsbyLink)`
    color: ${({ colour }) => colour ? colour : '#222222'};
    display: block;
    font-family: roboto-bold;
    transition: opacity .3s;
    font-weight: 500;
    line-height: inherit;
    ${({ uppercase }) => uppercase && `
        text-transform: uppercase;
    `};
    &:hover {
        opacity: .7;
    }
    @media(max-width: ${maxWidth('lg')}) {
        margin-bottom: ${rem(12)};
        font-size: ${rem(20)};
    }
    @media(min-width: ${minWidth('lg')}) {
        padding: 0 ${rem(12)};
        font-size: ${rem(16)};
    }
`;

export const LinkUnderline = styled(GatsbyLink)`
    color: #ffffff;
    font-family: roboto-bold;
    font-size: ${rem(14)};
    line-height: inherit;
    &:hover {
        text-decoration: underline;
    }
`;

export const Link = styled(GatsbyLink)`
    transition: color .3s;
    color: ${colours.RAISEN_BLACK};
    text-decoration: underline;
    &:hover {
        color: ${colours.NICKEL}
    }
`;

