import React from 'react';
import { Link } from './styled';

export default ({ children, ...rest }) => <Link {...rest}>{children}</Link>;

export const LinkActionExternal = ({ href, children, ...rest }) => {
    function openLink(e) {
        e.preventDefault();
        window.open(href, '_blank');
    }
    return <Link onClick={openLink} as="a" href={href} {...rest}>{children}</Link>;
};