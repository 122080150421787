import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { rem } from 'lib/units';
import * as colours from 'lib/theme/colours';

export const Link = styled(GatsbyLink)`
    color: ${colours.RAISEN_BLACK};
    background: none;
    box-shadow: inset 0 2px 0 0 ${colours.RAISEN_BLACK}, 
                inset -2px 0 0 0 ${colours.RAISEN_BLACK}, 
                inset 0 -2px 0 0 ${colours.RAISEN_BLACK}, 
                inset 2px 0 0 0 ${colours.RAISEN_BLACK};
    display: inline-block;
    font-family: 'roboto-regular';
    font-size: ${({ size }) => size === 'large' ? rem(20) : rem(14)};
    padding: ${({ size }) => size === 'large' ? `${rem(24)} ${rem(36)}` : `${rem(12)} ${rem(18)}`};
    position: relative;
    transition: color 0.38 cubic-bezier(.2,0.95,.4,0.95);
    &:after {
        background-size: 225% 100%!important;
        background-position: right!important;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${colours.RAISEN_BLACK};
        transition: transform 0.38s cubic-bezier(.2,0.95,.4,0.95),
                    background-size 0.38s ease;
        transform: scale(0,1);
        transform-origin: right;
        z-index: -1;
    }
    &:hover:after {
        background-position: left!important;
        background-size: 100% 100%!important;
        transform: scale(1,1);
        transform-origin: left;
        
    }
    &:hover {
        color: white;
    }
`;