import styled from 'styled-components';
import * as colours from 'lib/theme/colours';

const BAR_SIZE = '3px';

export const MenuButton = styled.div`
    cursor: pointer;
    position: relative;
    height: 19px;
    width: 25px;
    &:before,
    &:after {
        background-color: ${colours.RAISEN_BLACK};
        content: '';
        display: block;
        position: absolute;
        height: ${BAR_SIZE};
        right: 0;
        width: 100%;
        transition: all .38s cubic-bezier(.2,0.95,.4,0.95);
    }
    &:after {
        bottom: 0;
        width: 50%;
    }
    &:hover:after {
        width: 100%;
    }
    ${({ toggleMenu }) => toggleMenu && `
        &:before,
        &:after {
            transform: rotate(45deg);
            top: 8px;
        }
        &:after {
            transform: rotate(-45deg);
            top: 8px;
        }
        &:hover:after,
        &:after {
            width: 100%;
        }
        ${Stripe} {
            opacity: 0;
            transform: translateX(100%);
            width: 100%;
        }
    `}
`;

export const Stripe = styled.span`
    background-color: ${colours.RAISEN_BLACK};
    display: block;
    position: absolute;
    right: 0;
    // TODO
    transition: opacity .38s cubic-bezier(.2,0.95,.4,0.95), width .38s cubic-bezier(.2,0.95,.4,0.95);
    top: 8px;
    height: ${BAR_SIZE};
    width: 75%;
    ${MenuButton}:hover & {
        width: 100%;
    }
`;

