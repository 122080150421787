import styled from 'styled-components';
import { rem } from 'lib/units';
import { Text } from 'components/common/typography';
import * as colours from 'lib/theme/colours';

export const Container = styled.div`

`;

export const Label = styled(Text).attrs({ as: 'span', appearance: 'longPrimer' })`
    color: ${colours.ASH_GREY};
    margin-right: ${rem(6)};
`;

export const Link = styled.a`
    color: ${colours.ASH_GREY};
    transition: color .5s ease-in-out;
    text-transform: uppercase;
    &:hover {
        color: ${colours.BRAND_PRIMARY};
    }
`;
