import robotoBlackWoff2 from 'fonts/roboto-black.woff2';
import robotoBlackWoff from 'fonts/roboto-black.woff';

import robotoBoldWoff2 from 'fonts/roboto-bold.woff2';
import robotoBoldWoff from 'fonts/roboto-bold.woff';

import robotoRegularWoff2 from 'fonts/roboto-regular.woff2';
import robotoRegularWoff from 'fonts/roboto-regular.woff';

import robotoLightWoff2 from 'fonts/roboto-light.woff2';
import robotoLightWoff from 'fonts/roboto-light.woff';

// https://github.com/gatsbyjs/gatsby/issues/2583#issuecomment-340722928

export default`
@font-face {
    font-family: 'roboto-black';
    src: url(${robotoBlackWoff2}) format('woff2'),
        url(${robotoBlackWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-regular';
    src: url(${robotoRegularWoff2}) format('woff2'),
        url(${robotoRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-bold';
    src: url(${robotoBoldWoff2}) format('woff2'),
        url(${robotoBoldWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-light';
    src: url(${robotoLightWoff2}) format('woff2'),
        url(${robotoLightWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
`;
