import styled from 'styled-components';
import { Link } from 'gatsby';
import { rem } from 'lib/units';
import { minWidth, maxWidth } from 'lib/breakpoints';
import { makeContainer } from 'lib/mixins';
import * as transitions from 'lib/transitions';

export const Container = styled.div`
    background-color: rgba(255, 255, 255, 0.95);
    border-bottom: 1px solid #e5e5e5;
    position: fixed;
    width: 100%;
    z-index: 1000;
`;

export const Header = styled.header`
    ${makeContainer()};
    align-items: center;
    display: flex;
    padding: ${rem(6)} ${rem(12)};
    justify-content: space-between;
    @media(min-width: ${minWidth('lg')}) {
        padding: ${rem(12)} ${rem(12)};
    }
    @media(max-width: ${maxWidth('lg')}) {
        &:after {
            transition: opacity ${transitions.DEFAULT_TRANSITION_DURATION} ${transitions.DEFAULT_TRANSITION_FUNC};
            background: #000000;
            content: '';
            display: block;
            position: fixed;
            opacity: 0;
            height: 0;
            width: 0;
            left: 0;
            top: 0; 
            z-index: 1;
            ${({ menuOpen }) => menuOpen && `
                width: 100vw;
                height: 100vh;
                opacity: .4;
            `}
        }
    }
    @media(min-width: ${minWidth('lg')}) {
        position: relative;
    }
`;

export const LogoLink = styled(Link)`
    display: block;
    width: 100px;
    @media(min-width: ${minWidth('lg')}) {
        width: 193px;
    }
`;

export const Spacer = styled.div`
    display: none;
    text-align: right;
    @media(min-width: ${minWidth('lg')}) {
        display: block;
        width: 193px;
    }
`;

export const NavContainer = styled.div`
    // Off canvas navigation container. Handles slide in/out
    @media(max-width: ${maxWidth('lg')}) {
        background: white;
        padding-top: ${rem(55)};
        position: fixed;
        right: -300px;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        transform: translateX(0);
        transition: transform ${transitions.DEFAULT_TRANSITION_DURATION} ${transitions.DEFAULT_TRANSITION_FUNC};
        width: 300px;
        z-index: 1000;
        ${({ menuOpen }) => menuOpen && `
            transform: translateX(-300px);
        `}
    }
    // Topbar navigation container
    @media(min-width: ${minWidth('lg')}) {
        flex: 1;
    }
`;

export const MenuButtonContainer = styled.div`
    align-items: center;
    display: flex;
    z-index: 1000;
    > * {
        margin-left: ${rem(24)};
    }
    @media(min-width: ${minWidth('lg')}) {
        display: none;
    }
`;