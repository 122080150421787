import styled from 'styled-components';
import { rem, lineHeight } from 'lib/units';
import * as colours from 'lib/theme/colours';
import { minWidth } from 'src/lib/breakpoints';
// export { default as Heading } from './heading';
// export { default as Text } from './text';

const getHeadingColour = ({ colour, light }) => {
    if(colour) {
        return colour;
    }

    return light ? colours.WHITE : colours.RAISEN_BLACK
};

// export const mainStyle = (fontSize) => `
//     font-size: ${rem(fontSize)};
//     letter-spacing: 0;
//     font-family: 'roboto-bold';
//     line-height: ${lineHeight(42, 42)};
//     // margin: ${({ margin }) => margin ? margin : `0 0 ${rem(24)} 0`};
//     @media (min-width: ${minWidth('lg')}) {
//         font-size: ${rem(fontSize)};
//         // margin: ${({ margin }) => margin ? margin : `0 0 ${rem(24)} 0`};
//     }
// `;

export const Heading = styled.h1`
    color: ${getHeadingColour};
    ${({ center }) => center && `text-align: center;`}
    ${({ upper }) => upper && `text-transform: uppercase;`}

    ${({ appearance }) => appearance === 'cannon' && `
        font-display: block;
        font-size: ${rem(40)};
        font-family: 'roboto-black';
        line-height: ${lineHeight(90, 77)};
        font-weight: 400;
        margin: 0 0 ${rem(12)} 0;
        @media (min-width: ${minWidth('md')}) {
            font-size: ${rem(90)};
            letter-spacing: -4px;
        }
    `}

    ${({ appearance }) => appearance === 'trafalgar' && `
        font-size: ${rem(42)};
        letter-spacing: 0;
        font-display: block;
        font-family: 'roboto-black';
        line-height: ${lineHeight(60, 72)};
        font-weight: 400;
        margin: 0 0 ${rem(24)} 0;
        @media (min-width: ${minWidth('lg')}) {
            font-size: ${rem(60)};
        }
    `}

    ${({ appearance }) => appearance === 'paragon' && `
        font-size: ${rem(28)};
        letter-spacing: 0;
        font-family: 'roboto-light';
        line-height: ${lineHeight(28, 34)};
        font-weight: 400;
        margin: ${rem(24)} 0 ${rem(6)} 0;
        @media (min-width: ${minWidth('lg')}) {
            font-size: ${rem(28)};
        }
    `}

    // ${({ appearance }) => appearance === 'doublePica' && `
    //     font-size: ${rem(28)};
    //     letter-spacing: 0;
    //     font-family: 'roboto-bold';
    //     line-height: ${lineHeight(28, 34)};
    //     font-weight: 400;
    //     margin: ${rem(24)} 0 ${rem(6)} 0;
    //     @media (min-width: ${minWidth('lg')}) {
    //         font-size: ${rem(28)};
    //     }
    // `}

    // ${({ appearance }) => appearance === 'greatPrima' && `
    //     font-size: ${rem(18)};
    //     letter-spacing: 0;
    //     font-family: 'roboto-light';
    //     line-height: ${lineHeight(28, 34)};
    //     font-weight: 400;
    //     margin: ${({ margin }) => margin ? margin : `0 0 ${rem(24)} 0`};
    //     @media (min-width: ${minWidth('lg')}) {
    //         font-size: ${rem(18)};
    //     }
    // `}
`;

Heading.defaultProps = {
    appearance: 'cannon'
}

// Text

const getTextMargin = ({ appearance, margin }) => {
    if(margin) {
        return margin;
    }

    switch(appearance) {
        case 'pica': return `0 0 ${rem(24)} 0`;
        case 'longPrimer': return `0 0 ${rem(12)} 0`;
        case 'minion': return `0 0 ${rem(12)} 0`;
        default: return `0 0 ${rem(12)} 0`;    // bodyCopy
    }
};

const getTextAppearance = ({ appearance }) => {
    switch(appearance) {
        case 'pica': return `
            font-size: ${rem(24)};
        `;
        case 'minion': return `
            font-size: ${rem(12)};
        `;
        case 'longPrimer': return `
            font-size: ${rem(14)};
        `;
        default: return `
            font-size: ${rem(16)};
        `;
    }
};

const getTextColour = ({ colour, light }) => {
    if(colour) {
        return colour;
    }

    return light ? colours.bodyTextLight : colours.bodyText
};

// TODO defulat type
export const Text = styled.p`
    color: ${getTextColour};
    font-family: 'roboto-regular';
    font-weight: 400;
    line-height: ${lineHeight(16, 28)};
    margin: ${getTextMargin};
    text-transform: ${({ upper }) => upper ? 'uppercase' : 'none'};
    opacity: ${({ opacity }) => opacity ? opacity : 1};

    ${getTextAppearance};

    ${({ appearance }) => appearance === 'pica' && `
        line-height: ${lineHeight(22, 30)};
    `}

    ${({ appearance }) => appearance === 'longPrimer' && `
        line-height: 1.5;
    `}

    ${({ appearance }) => appearance === 'minion' && `
        
    `}
`;
