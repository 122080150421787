import React from 'react';
import { Text } from 'components/common/typography';
import * as colours from 'lib/theme/colours';

const Copyright = () => (
    <Text as="small" appearance="minion" colour={colours.ASH_GREY}>
        &copy;{new Date().getFullYear()} Activ Business Solutions
    </Text>
);

export default Copyright;