import styled from 'styled-components';
import { Text } from 'src/components/common/typography';
import { rem } from 'lib/units';

export const Wrapper = styled(Text).attrs({ appearance: 'longPrimer' })`
    color: #fff;
    margin-bottom: ${rem(6)};
    span {
        display: block;
    }
`;