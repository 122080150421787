import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image, article, ogType }) => {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
    } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
        ogType: ogType || 'website',
    };

    return (
        <Helmet 
            htmlAttributes={{
                lang: 'en',
            }}
            title={seo.title} 
            titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="og:type" content="website" />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.image && <meta property="og:image" content={seo.image} />}
        </Helmet>
    )
}
export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  ogType: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  ogType: null,
};

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                defaultImage: image
            }
        }
    }
`;