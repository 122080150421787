import styled from 'styled-components';
import { minWidth, maxWidth } from 'lib/breakpoints';
import { rem } from 'lib/units';

export const NavList = styled.ul`
    @media(min-width: ${minWidth('lg')}) {
        display: flex;
        justify-content: center;
    }
`;

export const NavListItem = styled.li`
    margin: 0 ${rem(12)};
    @media(min-width: ${minWidth('lg')}) {
        position: relative;
        line-height: 72px; // TODO note this is temporary. Fix the hover.
    }
    @media(hover: none) {
        > a {
            pointer-events: none;
        }
        
    }
    ${({ visibility }) => visibility === 'sidebar' && `
        @media(min-width: ${minWidth('lg')}) {
            display: none;
        }
    `}
    ${({ visibility }) => visibility === 'desktop' && `
        display: none;
        @media(min-width: ${minWidth('lg')}) {
            display: block;
        }
    `}
`;

NavListItem.defaultProps = {
    visibility: 'default',
};

export const NavListLink = styled.a`
    @media(max-width: ${maxWidth('lg')}) {
        background-color: #f7f7f7;
        border-bottom: 1px solid #e5e5e5;
    }
`;

export const SubNavListExtra = styled.div`
    @media(max-width: ${maxWidth('lg')}) {
        margin-bottom: 30px;
    }
    @media(min-width: ${minWidth('lg')}) {
        display: flex;
        left: -9999px;
        min-width: 300px;
        position: absolute;
        text-align: center;
        opacity: 0;
        transition: opacity .4s ease-in-out;
        transform: translateX(-50%);
        z-index: 1000;
        width: 300px;
        top: 100%;
        ${NavListItem}:hover & {
            left: 50%;
            opacity: 1;
        }
    }
`;

export const SubNavList = styled.ul`
    @media(min-width: ${minWidth('lg')}) {
        background: white;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        margin-top: 12px;
        padding: 48px 0;
        position: relative;
        width: 100%;
        &:before {
            content: '';
            display: block;
            left: 50%;
            position: absolute;
            top: -12px;
            transform: translateX(-50%);
            width: 0; 
            height: 0; 
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #e5e5e5;
        }
    }
`;

export const SubNavListItem = styled.li`
    margin-bottom: ${rem(12)};
    line-height: 1;
`;

export const SubNavListLink = styled.a`

`;

