import React from 'react';
import Icon from './components/icon';
import BubblesIcon from './svg/bubbles.svg';

const Index = (props) => (
    <Icon {...props}>
        <BubblesIcon />
    </Icon>
);

export default Index;