// Greys & Blacks
export const NICKEL = '#707070';    // NICKEL
export const RAISEN_BLACK = '#222222';
export const ASH_GREY = '#b7b7b7';
export const CULTURED = '#f5f5f5';

export const heading = '#111111';
export const WHITE = '#ffffff';
export const bodyText = '#676767';
// export const bodyText = '#3b323d';
export const bodyText50 = '#868491';
export const bodyTextLight = '#ffffff';

// export const brand = '#48a8fe';
// export const brand = '#0ec5e3';
export const BRAND_PRIMARY = '#0ec5e3'; // SPIRO DISCO BALL
export const BRAND_SECONDARY = '#195b75';  // BLUE SAPPHIRE
export const HIGHLIGHT = '#da3654';

// PRIMARY
// SECONDARY
// BLACK
// DARK_GREY
// LIGHT_GREY

// TODO remove
// export const iconFooter = ASH_GREY;


// Interesting pallette
// https://coolors.co/00849a-195b75-222222-707070-b7b7b7
// With brand blue
// https://coolors.co/0ec5e3-195b75-222222-707070-b7b7b7
