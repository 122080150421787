import React from 'react';
import Icon from './components/icon';
import FacebookSVG from './svg/facebook.svg';

const FacebookIcon = () => (
    <Icon>
        <FacebookSVG />
    </Icon>
);

export default FacebookIcon;