import React from 'react';
import { Container, Label, Link } from './styled';
import Address from 'components/common/address';
import { NavLink } from 'components/common/link';
import IconList from 'components/common/layout/footer/icon-list';
import FacebookIcon from 'components/common/icons/facebook';
import LinkedInIcon from 'components/common/icons/linkedin';
import TwitterIcon from 'components/common/icons/twitter';
import { generateTelNo, generateEmailString } from 'utils/helpers';

const email = generateEmailString();
const tel = generateTelNo();

const Index = () => (
    <Container>
        <Address
            name="Activ Business Solutions"
            description="Thames Valley's leading supplier of Physical and Digital Office Products"
            line1="Unit 5 Wheelers Yard"
            line2="87 High Street"
            town="Great Missenden"
            postcode="HP16 0AL"
            tel="07435 268081"
        />
        <ul>
            <li><Label>t</Label> <NavLink as="a" href={`tel:${tel}`} appearance="meta">{tel}</NavLink></li>
            <li><Label>e</Label> <NavLink as="a" href={`mailto:${email}`} appearance="meta">{email}</NavLink></li>
        </ul>
        <IconList>
            <IconList.Item>
                <Link href="http://www.activ-business.co.uk/articles/contact" aria-label="Find us on Facebook"><FacebookIcon /></Link>
            </IconList.Item>
            <IconList.Item>
                <Link href="https://www.linkedin.com/company-beta/11217219/" aria-label="Find us on Linked In"><LinkedInIcon /></Link>
            </IconList.Item>
            <IconList.Item>
                <Link href="https://twitter.com/ActivBusinessUK" aria-label="Find us on Twitter"><TwitterIcon /></Link>
            </IconList.Item>
        </IconList>
    </Container>
);

export default Index;
