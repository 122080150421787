import React from 'react';
import Helmet from 'react-helmet';
import BaseStyles from 'components/common/styles';
import { Container, Main, Content } from './styled';
import Header from 'components/common/layout/header';
// import Toolbar from 'components/common/layout/toolbar';
import Footer from 'components/common/layout/footer/footer';

const Layout = ({ children }) => (
    <Container>
        <Helmet
            meta={[
                { name: 'google-site-verification', content: 'EXV_0xmkqN2S4CBNHvSGHQVFkPD-MLK8rI24iafNRyM' }
            ]}
        />
        <Content>
            {/* <Toolbar /> */}
            <Header />
            <BaseStyles />
            <Main>
                {children}
            </Main>
        </Content>
        <Footer />
    </Container>
);

export default Layout;