import styled from 'styled-components';

import { makeContainer } from 'src/lib/mixins';
import { minWidth, maxWidth } from 'src/lib/breakpoints';
import { rem } from 'src/lib/units';

export const Footer = styled.footer`
    flex-shrink: 0;
    // background: #f3f3f3;
    background: #111;
    // padding-bottom: ${rem(80)};
    padding-top: ${rem(80)};
    padding-bottom: ${rem(30)};

    @media (max-width: ${maxWidth('lg')}) {
        text-align: center;
    } 
`;

export const Container = styled.div`
    ${makeContainer()}
    padding-left: 60px;
    padding-right: 60px;
`;

export const Grid = styled.div`
    @media (min-width: ${minWidth('lg')}) {
        display: flex;
        justify-content: space-between;
        > :first-child {
            order: 2;
        }
        > :last-child {
            order: 1;
        }
    }
`;

export const Nav = styled.div`
    @media (max-width: ${maxWidth('lg')}) {
        margin-bottom: ${rem(24)};
    }
`;

export const Base = styled.div`
    margin-top: ${rem(36)};
`;


export default Footer;