import styled from 'styled-components';

export const Icon = styled.span`
    color: inherit;
    display: inline-block;
    height: ${({ height }) => height ? height : '24px'};
    width: ${({ width }) => width ? width : '24px'};

    svg {
        fill: currentColor;
        height: 100%;
        width: 100%;
    }
`;