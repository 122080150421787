import React from 'react';
import Icon from './components/icon';
import SVG from './svg/envelope.svg';

const Index = (props) => {
    return (
        <Icon {...props}>
            <SVG />
        </Icon>
    );
};

export default Index;