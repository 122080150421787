import React from 'react';
import { Footer, Container, Grid, Nav, Base } from './styled';
import { NavLink } from 'components/common/link';
import Copyright from 'components/common/layout/footer/copyright'
import { List, Item } from 'components/common/layout/footer/action-list';
import Contact from 'components/common/layout/footer/contact';

const Index = () => (
    <Footer>
        <Container>
            <Grid>
                <Nav>
                    <List>
                        <Item><NavLink to="/about" appearance="meta" uppercase="true">More about us</NavLink></Item>
                        <Item><NavLink to="/services/corporate-social-responsibility" appearance="meta" uppercase="true">CSR</NavLink></Item>
                        <Item><NavLink as="a" href="http://shop.activ-business.co.uk/" appearance="meta" uppercase="true">Shop</NavLink></Item>
                        {/* <Item><NavLink to="" appearance="meta">Promotion</NavLink></Item> */}
                    </List>
                </Nav>
                <Contact />
            </Grid>
            <Base>
                <Copyright />  
            </Base>
        </Container>
    </Footer>
);

export default Index;
